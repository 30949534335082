export default {
  name: 'FileUploader',
  data: () => ({
    isValidExtension: false,
    isSizeExceeded: false,
  }),
  methods: {
    uploadFile() {
      const input = document.getElementById('fileInput')
      input.click()
    },
    async onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files

      this.isSizeExceeded = !files.length || this.exceedFileSize(files[0].size)
      if (this.isSizeExceeded) {
        this.fileName = 'Tamaño máximo superior a 1.6mb o archivo no válido. Pruebe con otro.'
        this.$emit('fileSelected', null)
        return
      }

      if (this.validateExtension && this.validExtensions) {
        const fileExtension = files[0].name.split('.').pop()
        this.isValidExtension = this.validExtensions.some(
          (extension) => fileExtension.toLowerCase() === extension.toLowerCase()
        )
        if (!this.isValidExtension) {
          this.fileName = 'El tipo de archivo seleccionado no es válido.'
          this.$emit('fileSelected', null)
          return
        }
      }

      const fileInBase64 = await this.toBase64(files[0])
      const fileSplit = fileInBase64.split(',')
      const fileName = files[0].name
      const objectToSend = {
        base64: fileSplit[1],
        name: fileName,
      }
      this.fileName = fileName
      this.$emit('fileSelected', objectToSend)
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },

    exceedFileSize(size) {
      const MAX_SIZE = 1638400

      return size > MAX_SIZE
    },
  },
  computed: {
    isValidFile() {
      return !this.isSizeExceeded && this.isValidExtension && this.fileName
    },
  },
}
