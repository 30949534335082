<template>
  <div class="app-file-uploader">
    <span @click="uploadFile()">
      <information
        v-if="!isValidFile"
        :text="text"
        icon="/imgs/download.svg"
        class="information-component cursor-pointer flex justify-end"
      />
    </span>
    <span @click="deleteFile()">
      <information
        v-if="isValidFile"
        :text="$t('FILE_UPLOADER.DELETE')"
        icon="/imgs/cross.svg"
        class="information-component cursor-pointer flex justify-end"
      />
    </span>

    <p class="pt-15 text-15 color-grey-500 flex justify-end">
      {{ fileName }}
      <img
        v-if="isValidFile"
        class="pl-3"
        src="/imgs/success.svg"
        alt="success img"
        height="22px"
        width="22px"
      />
    </p>
    <input id="fileInput" type="file" :accept="accept" @change="onFileChange" />
  </div>
</template>

<script>
import FileUploader from '@/mixins/FileUploader'

export default {
  name: 'AppFileUploader',
  components: {
    Information: () => import('@/components/Information'),
  },
  mixins: [FileUploader],
  props: {
    text: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    fileName: undefined,
    validateExtension: true,
    validExtensions: ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png'],
  }),

  computed: {
    accept() {
      if (!this.validExtensions) {
        return ''
      }

      const mimeTypes = {
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        png: 'image/png',
      }

      const accept = new Set()
      for (const extension of this.validExtensions) {
        accept.add(mimeTypes[extension])
      }

      return [...accept].join(',')
    },
  },

  methods: {
    deleteFile() {
      this.fileName = ''
      this.$emit('fileSelected', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.input-label {
  width: 150px;
}

.app-file-uploader {
  // stylelint-disable-next-line selector-max-id
  #fileInput {
    visibility: hidden;
  }

  .information-component {
    // stylelint-disable-next-line selector-max-compound-selectors
    ::v-deep .icon {
      transform: rotate(180deg);
    }
  }
}
</style>
